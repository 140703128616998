import Nav from "./nav"
import React from "react"

export default function Header() {
  return (
    <header>
      <Nav />
    </header>
  )
}
