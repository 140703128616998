import PropTypes from "prop-types"
import React from "react"

export default function Logo({ fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 130 110"
      fill={fill}
      className="w-auto h-10"
    >
      <path d="M63.5 3.3C62.7 5.8 51.6 16 51.6 16c6.4 4 13.1 13.3 13.1 13.3C66.9 26.6 77 16.7 77 16.7 70.5 12.5 63.5 3.3 63.5 3.3zm1.6 22.8s-7.9-8.1-8.8-9.5c0 0 6.7-6.1 7.1-7.3 0 0 6.7 6.9 10.5 7.8l-8.8 9z"></path>
      <path d="M14.2 101.1s2.7-2.9 4.5.4c2 3.7-7.7 4.6-7.6 0 0 0-1-3.8 4.5-7.2s13.1-3.3 19.9-2.8 26.2.9 39.3-26.5C87.3 38.9 69 20.8 62.6 15a27.3 27.3 0 0 0-26.7-5.5 23 23 0 0 0-16 23.5c.8 8.6 4.4 12.4 3.2 20S14.3 71.6 6 68c-4-1.7-3.7-5-1.9-6.5s4.1.4 3.9.8c-.2.7-1.3.7-1.7.9-.9.3-2.9 2.6 1.6 3.1s8.5-1.4 11.4-7.6 3.4-8.4-1.1-23 0-24.2 15-27.6 21.4.8 23.2 1.7a43 43 0 0 1 17.1 15.6A41.7 41.7 0 0 1 81 52.5c-.8 7.8-7 18.5-10.2 23-4 5.5-8.5 9.5-16.6 14.2a42.5 42.5 0 0 1-16.5 3.5c-4.6-.1-12.5-2.1-20.7 2.2-4.3 2.1-5.1 5.9-2.8 5.7z"></path>
      <path d="M59.2 70.1C58.4 72.6 44.6 86 44.6 86 51 90 60 96.7 60 96.7c2.2-2.7 13-12.8 13-12.8a71.8 71.8 0 0 1-13.8-13.8zm.1 24.6S48.9 87.3 48 85.9c0 0 9.9-7.2 10.3-8.4 0 0 6.1 5.2 9.9 6.1l-8.9 11.1z"></path>
      <path d="M90.5 104.6c-.5-.7.7-4.6 4-3.1 3.4 1.6 8.1 3.9 10.7.4 2.4-3.3.3-9.9-4.4-11.7a65.4 65.4 0 0 0-20.2-.2A37 37 0 0 1 62 86S28.6 67.5 49.2 33.2s54.9-23.3 62.2-18.3c7.3 5 14.2 9.4 13.3 24.4-.9 15.4-18 18.5-24.3 13.7-5.8-4.3-6.1-10.6-.4-14.6 3.6-2.5 6.6-1.7 8.6-.2 1.3.9 2.3 1.9 3 2.1 3.1.6 6.6-1.3 5.8-5.2-.7-3.4-4.2-4.4-5.5-2.7-1 1.3.3.7 1 .5.7-.3.4.1.6.2.1.1.3-.2.4.2.1.3-.2.5.2.7.6.3 1.6-.3.8 2.2-.6 1.8-3.3 1.5-4.5-2.4-1-3.4-8.8-4.8-12.4-1-3.1 3.2-8.5 7.9-4 15.5 4.5 7.5 12.6 11.1 21.5 7.7 8.9-3.5 14.1-14.3 9.8-27.5C120.9 15.2 106.2 6 82.4 8.1S45 30.9 42 39.2c-3 8.2-8.6 25.2 6.9 39.2a44.3 44.3 0 0 0 38.4 12.5c10.3-.9 16.8 2.3 15.9 6.5-1 4.8-4.8 2.9-9.3 1.2-4.8-1.7-7.8 4.8-5.1 7s2.8.7 1.7-1z"></path>
    </svg>
  )
}

Logo.propTypes = {
  fill: PropTypes.string,
}

Logo.defaultProps = {
  fill: "#222b2f",
}
